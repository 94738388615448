
import React from 'react';
import "./footer.css";
import Linkedin from './icons/linkedin-6-xl.png';
import Instagram from './icons/instagram-xl.png';
import Facebook from './icons/facebook-3-xl.png';



const Footer = () => {


  return (
    <div>
    <section className="row py-5 contact-us text-white bg-lightgray">
                  <section className="col-md-4 col-sm-12 order-md-1 ">
                              <h2 className='undeline'>Contact Us</h2> 

                        
                              <p className="mb-0 align-left cb-text">
                                <b>Operation Areas:</b>  Greenwood, Abbeville, Honea Path,
                                Laurens, and surrounding areas{" "}
                              </p>
                              <p className="mb-0 align-left cb-text">
                                <b>Main Phone Line:</b> (864)-323-1531{" "}
                              </p>
                              <p className="mb-0 align-left cb-text">
                                <b>Secondary Phone Line:</b> (864)-209-7164{" "} 
                              </p>
                              <p className="mb-0 align-left cb-text">
                                <b>Email:</b> therighttouch.homerepair@gmail.com wowzerzzz{" "}

                              </p>
                             
                    </section>

          
              
          

       
            <section className="col-md-4 col-sm-12 order-md-1 ">
              <h2 className='undeline text-center '></h2>
              
                                <div className='d-flex map-spacing '>
                                  <a href="https://www.linkedin.com/company/the-right-touch-construction-and-home-repair/?viewAsMember=true">  
                                      <img src={Linkedin} alt="linked in logo"></img>
                                  </a>
                                  
                              
                                  <a href="https://www.facebook.com/profile.php?id=61560294762896">  
                                      <img src={Facebook} alt="facebook logo"></img>
                                  </a>
                                
                                  <a href="https://www.instagram.com/the_right_touch_sc/">  
                                      <img src={Instagram} alt="instagram logo"></img>
                                  </a>
                                </div>
                                
                                
            </section>
            
            <section className="col-md-4 col-sm-12 order-md-1 ">
              <h2 className='undeline'></h2> 
              <div className='p-pbtn' >
              <button className="btn btn-warning ">
                <a className='p-pbtn' href="/privacy">
                Privacy Policy
                </a>
              </button>
              </div>
            </section>

        </section>
     


        <section className="row py-5 contact-us bg-lightgray text-grey">
          <div className="col-md-12 col-sm-12 order-md-1 move-left">
            
            &copy; 2024 The Right Touch LLC. All Rights Reserved.

          </div>
        </section>
    </div>
  );
};

export default Footer;