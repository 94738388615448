import React from "react";
import "./quote.css"; 

const Quote = () => {
    return (
        <div className="quote-container">
            <p>quotes bs are </p>
            <p className="author">— </p>
        </div>
    );
};

export default Quote;